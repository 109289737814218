<template>
	<div class="pt-7 container-fluid">
		<b-card>
			<b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getData">
				<i class="fas fa-sync-alt"></i>
			</b-button>
			<b-row class="filterSection">
				<b-col md="12" class="mb-4">
					<base-button type="success" @click="addNew" class="my--5"> <i class="fas fa-plus"></i> {{ $t('general.addnew') }} </base-button>
				</b-col>
				<b-col md="4">
					<base-input
						type="text"
						:label="$t('general.search_area')"
						:placeholder="$t('doctor.search_area')"
						v-model="filter.text"
						@input="searchTimeOut"
					>
					</base-input>
				</b-col>
				<b-col md="2">
					<base-input :label="$t('general.status')">
						<el-select v-model="filter.status" :placeholder="$t('general.select')" clearable @change="getData">
							<el-option :label="$t('general.active')" :value="true"></el-option>
							<el-option :label="$t('general.passive')" :value="false"></el-option>
						</el-select>
					</base-input>
				</b-col>
			</b-row>
		</b-card>
		<b-card no-body v-if="patients" class="p-4">
			<el-table
				class="table-responsive table"
				header-row-class-name="thead-light"
				:data="patients"
				v-loading="loading"
				style="width: 100%"
				:empty-text="$t('general.empty_table')"
				:row-class-name="tableRowClassName"
			>
				<el-table-column :label="$t('general.name')" prop="name" min-width="220"> </el-table-column>

				<el-table-column :label="$t('general.email')" prop="email" min-width="300">
					<template v-slot="{row}">
						<span>{{ row.email }}</span>
					</template>
				</el-table-column>

				<el-table-column :label="$t('general.phone')" prop="phone" min-width="160">
					<template v-slot="{row}">
						<span>{{ row.phone }}</span>
					</template>
				</el-table-column>

				<el-table-column :label="$t('profile.identity')" prop="identity" min-width="160">
					<template v-slot="{row}">
						<span v-if="!row.nontc">{{ row.identity }}</span>
					</template>
				</el-table-column>

				<el-table-column :label="$t('general.status')" prop="status" min-width="140">
					<template v-slot="{row}">
						<badge class="badge-dot mr-4" type="">
							<i :class="`bg-${status[row.status]}`"></i>
							<span class="status" :class="`text-${status[row.status]}`">{{ row.status ? $t('general.active') : $t('general.passive') }}</span>
						</badge>
					</template>
				</el-table-column>
				<el-table-column align="right" min-width="180">
					<template slot-scope="scope">
						<base-button type="primary" size="sm" @click="openProcessModal(scope.$index)">{{ $t('general.process') }}</base-button>
					</template>
				</el-table-column>
			</el-table>
			<b-card-footer class="py-4 d-flex justify-content-end" v-if="pages > 1 && !loading">
				<base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
			</b-card-footer>
		</b-card>

		<modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose">
			<validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
				<h6 slot="header" class="modal-title">{{ modal.data.uuid ? $t('patient.edit') : $t('patient.add') }}</h6>
				<hr />
				<b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading">
					<b-row>
						<b-col md="6" class="mb-2">
							<label class="form-control-label">{{ $t('general.status') }}</label>
							<b-form-checkbox v-model="modal.data.status" class="witchCheck dblock" name="check-button" switch>
								{{ modal.data.status ? $t('general.active') : $t('general.passive') }}
							</b-form-checkbox>
						</b-col>
						<b-col md="6">
							<label class="form-control-label">{{ $t('general.nontc') }}</label>
							<b-form-checkbox v-model="modal.data.nontc" class="witchCheck dblock" name="check-button" switch>
								{{ modal.data.nontc ? $t('general.yes') : $t('general.no') }}
							</b-form-checkbox>
						</b-col>
					</b-row>
					<b-row>
						<b-col md="6">
							<base-input
								type="text"
								name="name"
								:nameAs="$t('profile.name_lastname')"
								:label="$t('profile.name_lastname')"
								:placeholder="$t('profile.name_lastname')"
								v-model="modal.data.name"
								required
							>
							</base-input>
						</b-col>
						<b-col md="6" v-if="!modal.data.nontc">
							<base-input
								type="text"
								name="identity"
								:nameAs="$t('profile.identity')"
								:label="$t('profile.identity')"
								:placeholder="$t('profile.identity')"
								v-model="modal.data.identity"
								required
							>
							</base-input>
						</b-col>
						<b-col md="6">
							<validation-provider :rules="'required'" :name="$t('profile.phone')" v-slot="{errors}">
								<label class="form-control-label">{{ $t('profile.phone') }}*</label>
								<VuePhoneNumberInput
									id="phoneNumber"
									v-model="modal.data.maskPhone"
									required
									class="mb-2"
									:translations="{
										example: $t('general.example'),
										countrySelectorError: $t('error.select_country'),
										countrySelectorLabel: $t('general.country_code'),
										phoneNumberLabel: $t('general.phone_number')
									}"
									:defaultCountryCode="modal.data.defaultCountryCode"
									show-code-on-list
									@update="onPhone"
									no-flags
								/>
								<div class="invalid-feedback" style="display: block" v-if="errors[0]">
									{{ errors[0] }}
								</div>
							</validation-provider>
						</b-col>
						<b-col md="6">
							<base-input
								type="email"
								name="email"
								:nameAs="$t('profile.email')"
								:label="$t('profile.email')"
								:placeholder="$t('profile.email')"
								v-model="modal.data.email"
								required
							>
							</base-input>
						</b-col>
						<b-col md="6">
							<label class="form-control-label"
								>{{ $t('general.password') }}
								<span v-if="modal.data.uuid">*</span>
								<template v-if="profile.superadmin && modal.data.temp">
									<small
										>( <strong>{{ modal.data.temp }}</strong> )</small
									>
								</template>
							</label>
							<div v-if="modal.data.uuid">
								<base-input
									type="password"
									name="password"
									:nameAs="$t('general.password')"
									:placeholder="$t('general.password')"
									v-model="modal.data.password"
									:rules="'min:6'"
								></base-input>
								<small>{{ $t('profile.pass_desc') }}</small>
							</div>
							<base-input
								type="password"
								name="password"
								:nameAs="$t('general.password')"
								:placeholder="$t('general.password')"
								v-model="modal.data.password"
								:rules="'required|min:6'"
								v-else
							></base-input>
						</b-col>
					</b-row>
					<hr />
					<b-alert show variant="warning" v-if="invalid || modal.data.phone == ''">
						<strong>{{ $t('warning.fill_title') }} : </strong> {{ $t('warning.fill') }}
					</b-alert>
					<base-button type="primary" native-type="submit" :disabled="modal.loading || invalid || modal.data.phone == ''">{{
						$t('save')
					}}</base-button>
					<base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{
						$t('close')
					}}</base-button>
				</b-form>
			</validation-observer>
		</modal>

		<modal :show.sync="processModal.status" modal-classes="modal-danger" modal-content-classes="bg-gradient-primary" size="sm">
			<h6 slot="header" class="modal-title">{{ $t('general.process') }}</h6>

			<div class="py-3 text-center">
				<base-button type="secondary" block size="sm" @click="doProcess('appointment')">{{ $t('general.appointments') }}</base-button>
				<base-button type="secondary" block size="sm" @click="doProcess('payment')">{{ $t('general.payments') }}</base-button>
				<base-button type="secondary" block size="sm" @click="doProcess('file')">{{ $t('general.files') }}</base-button>
				<base-button type="secondary" block size="sm" @click="doProcess('recipe')">{{ $t('general.receipes') }}</base-button>
				<base-button type="secondary" block size="sm" @click="doProcess('report')">{{ $t('general.reports') }}</base-button>
				<base-button type="secondary" block size="sm" @click="doProcess('note')">{{ $t('general.notes') }}</base-button>
				<base-button type="secondary" block size="sm" @click="doProcess('edit')">{{ $t('general.edit') }}</base-button>
				<base-button type="secondary" block size="sm" @click="doProcess('delete')">{{ $t('general.delete') }}</base-button>
			</div>

			<template slot="footer">
				<base-button type="link" class="text-white ml-auto" @click="processModal.status = false">{{ $t('general.close') }}</base-button>
			</template>
		</modal>

		<modal
			:show.sync="modals.status"
			:showClose="false"
			:backdropClose="false"
			:hideFooter="true"
			@close="modalsClose(modals.id)"
			:scrollable="true"
			:size="modals.size"
		>
			<div v-loading="modals.loading">
				<div v-if="!modals.loading">
					<template v-if="modals.id == 'report'">
						<h6 slot="header" class="modal-title">{{ $t('general.report_list') }}</h6>
						<hr class="my-2" />
						<b-alert class="posrel" variant="secondary" show v-for="(item, index) in reports" :key="index">
							<small>
								<strong>{{ $t('general.appointment_info') }} : </strong>{{ item.appointment.data.date | getIntDate }}
								{{ item.appointment.data.time | getIntTime }} - {{ $t('availability.' + item.appointment.data.type) }}</small
							>
							<hr class="my-2" />
							<strong>{{ item.doctor.title }} {{ item.doctor.name }}</strong
							><br />
							<p v-html="$options.filters.getHtmlText(item.text)"></p>
							<small>{{ item.createdAt | getDate }}</small>
						</b-alert>
						<b-alert variant="info" :show="reports.length === 0">{{ $t('info.no_reports') }}</b-alert>
					</template>
					<template v-if="modals.id == 'recipe'">
						<h6 slot="header" class="modal-title">{{ $t('general.recipe_list') }}</h6>
						<hr class="my-2" />
						<b-alert class="posrel" variant="secondary" show v-for="(item, index) in recipes" :key="index">
							<small>
								<strong>{{ $t('general.appointment_info') }} : </strong>{{ item.appointment.data.date | getIntDate }}
								{{ item.appointment.data.time | getIntTime }} - {{ $t('availability.' + item.appointment.data.type) }}</small
							>
							<hr class="my-2" />
							<strong>{{ item.doctor.title }} {{ item.doctor.name }}</strong
							><br />
							<p v-html="$options.filters.getHtmlText(item.text)"></p>
							<small>{{ item.createdAt | getDate }}</small>
						</b-alert>
						<b-alert variant="info" :show="recipes.length === 0">{{ $t('info.no_recipes') }}</b-alert>
					</template>
					<template v-if="modals.id == 'file'">
						<h6 slot="header" class="modal-title">{{ $t('general.file_list') }}</h6>
						<hr class="my-2" />
						<b-alert variant="info" :show="files.length === 0">{{ $t('info.no_files') }}</b-alert>
						<b-row>
							<b-col md="3" class="mb-4" v-for="(item, index) in files" :key="index">
								<div class="file-section">
									<div class="file-name">
										<a :title="item.originalName">{{ item.originalName }}</a>
									</div>
									<div class="file-date">{{ item.createdAt | getDate }}</div>
									<div class="file-icon">
										<i class="fas fa-file-image" v-if="item.type == 'image'"></i>
										<i class="fas fa-file-alt" v-else></i>
									</div>
									<div class="file-footer">
										<base-button type="primary" size="sm" @click="openFile(item.uuid)">{{ $t('general.open') }}</base-button>
									</div>
								</div>
							</b-col>
						</b-row>
					</template>
					<template v-if="modals.id == 'note'">
						<h6 slot="header" class="modal-title">{{ $t('general.notes') }}</h6>
						<hr class="my-2" />
						<div role="tablist" class="accordion" v-if="notes && notes.length > 0">
							<b-card no-body class="mb-1" v-for="(item, index) in notes" :key="index">
								<b-card-header header-tag="header" class="p-0" role="tab">
									<b-button block v-b-toggle="'accordion-' + index" variant="default" class="text-left">{{
										item.doctor.title + item.doctor.name
									}}</b-button>
								</b-card-header>
								<b-collapse :id="'accordion-' + index" accordion="note-accordion" role="tabpanel">
									<b-card-body>
										<b-card-text>
											{{ item.note }}
											<div class="text-right">
												<small class="text-primary">{{ item.createdAt | getDate }}</small>
											</div>
										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
						</div>
						<div v-else>
							<b-alert show variant="info"> <i class="fas fa-info-circle"></i> {{ $t('info.not_found_notes') }} </b-alert>
						</div>
					</template>
					<template v-if="modals.id == 'payment'">
						<h6 slot="header" class="modal-title">{{ $t('general.payments') }}</h6>
						<hr class="my-2" />
						<el-table
							class="table-responsive table"
							header-row-class-name="thead-light"
							:data="payments"
							style="width: 100%"
							:empty-text="$t('general.empty_table')"
						>
							<el-table-column width="200" :label="$t('appointment.date')">
								<template v-slot="{row}">
									<span v-if="row.availability[0] && row.availability[0].date != undefined"
										>{{ (row.availability[0].date, 'DD.MM.YYYY ddd' | getIntDate) }} / {{ row.availability[0].time | getIntTime }}</span
									>
								</template>
							</el-table-column>
							<el-table-column width="240" :label="$t('general.doctor')">
								<template v-slot="{row}">
									<span v-if="row.doctor[0] && row.doctor[0].name != undefined">{{ row.doctor[0].title }} {{ row.doctor[0].name }}</span>
								</template>
							</el-table-column>
							<el-table-column width="100" :label="$t('appointment.price')">
								<template v-slot="{row}"> {{ row.amount }} {{ row.currency | getCurrency }} </template>
							</el-table-column>
							<el-table-column width="160" :label="$t('general.payment_type')">
								<template v-slot="{row}">
									{{ $t('general.' + row.paymentMethod) }}
								</template>
							</el-table-column>
							<el-table-column width="160" :label="$t('general.payment_status')">
								<template v-slot="{row}">
									{{ $t('general.' + row.paymentStatus) }}
								</template>
							</el-table-column>
							<el-table-column :label="$t('payment.date')" min-width="180">
								<template v-slot="{row}">
									{{ row.updatedAt | getDate }}
								</template>
							</el-table-column>
						</el-table>
					</template>
					<template v-if="modals.id == 'appointment'">
						<h6 slot="header" class="modal-title">{{ $t('general.appointments') }}</h6>
						<hr class="my-2" />
						<el-table
							class="table-responsive table"
							header-row-class-name="thead-light"
							:data="appointments"
							style="width: 100%"
							:empty-text="$t('general.empty_table')"
						>
							<el-table-column :label="$t('general.appointment_status')" min-width="110">
								<template v-slot="{row}">
									<b-badge
										:variant="
											row.appointment_status == 'completed' ? 'success' : row.appointment_status == 'waiting' ? 'primary' : 'danger'
										"
									>
										<template v-if="row.appointment_status == 'completed'">{{ $t('general.completed') }}</template>
										<template v-if="row.appointment_status == 'waiting'">{{ $t('general.waiting') }}</template>
										<template v-if="row.appointment_status == 'canceled'">{{ $t('general.canceled') }}</template>
									</b-badge>
								</template>
							</el-table-column>
							<el-table-column :label="$t('appointment.date')" prop="data.date" min-width="180">
								<template v-slot="{row}">
									{{ (row.data.date, 'DD.MM.YYYY ddd' | getIntDate) }}
								</template>
							</el-table-column>
							<el-table-column :label="$t('appointment.time')" prop="data.time" min-width="100">
								<template v-slot="{row}">
									{{ row.data.time | getIntTime }}
								</template>
							</el-table-column>
							<el-table-column :label="$t('appointment.delay')" prop="data.min" min-width="100">
								<template v-slot="{row}"> {{ row.data.min }} {{ $t('general.min') }} </template>
							</el-table-column>
							<el-table-column :label="$t('appointment.type')" prop="availability.type" min-width="160">
								<template v-slot="{row}">
									<i :class="row.data.type == 'online' ? 'fas fa-video' : 'fas fa-building'"></i>
									{{ row.data.type == 'online' ? $t('general.conversation_online') : $t('general.conversation_office') }}
								</template>
							</el-table-column>
							<el-table-column width="240" :label="$t('general.doctor')">
								<template v-slot="{row}">
									<span v-if="row.doctor[0] && row.doctor[0].name != undefined">{{ row.doctor[0].title }} {{ row.doctor[0].name }}</span>
								</template>
							</el-table-column>
							<el-table-column width="100" :label="$t('appointment.price')">
								<template v-slot="{row}">
									<span v-if="row.payment[0] && row.payment[0].amount != undefined"
										>{{ row.payment[0].amount }} {{ row.payment[0].currency | getCurrency }}</span
									>
								</template>
							</el-table-column>
							<el-table-column width="160" :label="$t('general.payment_type')">
								<template v-slot="{row}">
									<span v-if="row.payment[0] && row.payment[0].paymentMethod != undefined">{{
										$t('general.' + row.payment[0].paymentMethod)
									}}</span>
								</template>
							</el-table-column>
							<el-table-column :label="$t('general.payment_status')" min-width="170">
								<template v-slot="{row}">
									<span v-if="row.payment[0] && row.payment[0].paymentStatus != undefined">
										<b-badge
											:variant="
												row.payment[0].paymentStatus == 'completed'
													? 'success'
													: row.payment[0].paymentStatus == 'waiting'
													? 'primary'
													: 'danger'
											"
										>
											{{ $t('general.' + row.payment[0].paymentStatus) }}
										</b-badge>
									</span>
								</template>
							</el-table-column>
							<el-table-column :label="$t('general.created_date')" min-width="200">
								<template v-slot="{row}">
									{{ row.createdAt | getDate }}
								</template>
							</el-table-column>
						</el-table>
					</template>
				</div>
			</div>
			<hr class="m-0" />
			<base-button type="link text-danger" class="ml-auto float-right" @click="modalsClose(modals.id)">{{ $t('close') }}</base-button>
		</modal>
	</div>
</template>

<script>
import {findPhoneNumbersInText} from 'libphonenumber-js'
import VuePhoneNumberInput from '@/components/PhoneInput'
import _ from 'lodash'
import {mapGetters} from 'vuex'
import {handleError} from '@/utils/func'
import {getCookie} from '@/utils/cookie'
import config from '@/config'

const token = getCookie('user-token')
const session = getCookie('user-session')
let self
let emptyModal = {
	status: true,
	nontc: false,
	defaultCountryCode: null,
	countryCode: '',
	maskPhone: '',
	name: '',
	email: '',
	phone: '',
	identity: ''
}
export default {
	components: {
		VuePhoneNumberInput
	},
	computed: {
		...mapGetters({
			patients: 'getPatients',
			total: 'getPatientTotal',
			pages: 'getPatientPages',
			userType: 'getUserType',
			files: 'getFiles',
			notes: 'getNotes',
			recipes: 'getRecipes',
			reports: 'getReports',
			comments: 'getComments',
			payments: 'getPayments',
			appointments: 'getAppointments',
			profile: 'getProfile'
		})
	},
	data() {
		return {
			modals: {
				loading: false,
				status: false,
				size: '',
				id: false,
				note: {
					index: false,
					data: null
				}
			},
			processModal: {
				status: false,
				index: false
			},
			modal: {
				loading: false,
				status: false,
				data: _.clone(emptyModal)
			},
			loading: true,
			status: {
				true: 'success',
				false: 'danger'
			},
			pagination: {
				limit: 10,
				page: 1
			},
			filter: {}
		}
	},
	methods: {
		openFile(uuid) {
			var xhr = new XMLHttpRequest()

			xhr.open('GET', config.VUE_APP_CDNURL + '/' + uuid)
			xhr.onreadystatechange = () => {
				if (xhr.status == 200 && xhr.response) {
					var data_url = URL.createObjectURL(xhr.response)
					window.open(data_url)
				}
			}
			xhr.responseType = 'blob'
			xhr.setRequestHeader('Authorization', token)
			xhr.setRequestHeader('Session', session)
			xhr.send()
		},
		onPhone(payload) {
			if (payload.isValid) {
				this.$set(self.modal.data, 'countryCode', payload.countryCallingCode)
				this.$set(self.modal.data, 'phone', payload.nationalNumber)
			} else {
				this.$set(self.modal.data, 'countryCode', '')
				this.$set(self.modal.data, 'phone', '')
			}
		},
		searchTimeOut() {
			if (self.timer) {
				clearTimeout(self.timer)
				self.timer = null
			}
			self.timer = setTimeout(() => {
				self.getData()
			}, 800)
		},
		doProcess(index) {
			self.processModal.status = false
			let row = self.patients[self.processModal.index]
			self.modals.size = ''
			switch (index) {
				case 'appointment':
					self.getPatientAppointment(row.uuid)
					self.modals.size = 'lg'
					break
				case 'file':
					self.getPatientFile(row.uuid)
					break
				case 'note':
					self.getPatientNote(row.uuid)
					break
				case 'payment':
					self.getPatientPayment(row.uuid)
					self.modals.size = 'lg'
					break
				case 'recipe':
					self.getPatientRecipe(row.uuid)
					break
				case 'report':
					self.getPatientReport(row.uuid)
					break
				case 'edit':
					self.handleEdit(row)
					break
				case 'delete':
					self.handleDelete(row.uuid)
					break
				default:
					break
			}

			self.modals.id = index
			if (['edit', 'delete'].indexOf(index) === -1) {
				self.modals.status = true
			}
		},
		modalsClose(id) {
			self.modals.loading = false
			self.modals.status = false
			self.modals.id = false
		},
		openProcessModal(index) {
			self.processModal.index = index
			self.processModal.status = true
		},
		tableRowClassName({row, rowIndex}) {
			if (!row.status) {
				return 'table-danger'
			}
			return ''
		},
		changePage(val) {
			self.loading = true
			self.pagination.page = val
			self.getData()
		},
		handleEdit(row) {
			self.modal.loading = false
			self.modal.status = true
			self.modal.data = _.cloneDeep(emptyModal)
			self.modal.data = _.merge(self.modal.data, row)
			if (self.modal.data.phone && self.modal.data.phone != '') {
				let parsePhone = findPhoneNumbersInText('+' + self.modal.data.phone.toString())
				let country = parsePhone[0].number.country
				let phone = parsePhone[0].number.nationalNumber
				let countryCode = parsePhone[0].number.countryCallingCode
				this.$set(self.modal.data, 'defaultCountryCode', country)
				this.$set(self.modal.data, 'countryCode', countryCode)
				this.$set(self.modal.data, 'maskPhone', phone)
			}
		},
		handleDelete(uuid) {
			this.$swal({
				title: self.$i18n.t('quest.sure'),
				text: self.$i18n.t('quest.delete_info'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#f5365c',
				confirmButtonText: self.$i18n.t('quest.delete'),
				cancelButtonText: self.$i18n.t('quest.cancel')
			}).then(result => {
				if (result.value) {
					self.loading = true
					self.$store
						.dispatch('PATIENT_DELETE', uuid)
						.then(resp => {
							self.loading = false
							self.getData()
							self.$notify({type: 'success', message: self.$i18n.t('success.positive')})
						})
						.catch(err => {
							self.loading = false
							handleError(self, err)
						})
				}
			})
		},
		modalClose() {
			self.modal.loading = false
			self.modal.status = false
			self.modal.data = _.clone(emptyModal)
		},
		addNew() {
			self.modal.loading = false
			self.modal.status = true
			self.modal.data = _.clone(emptyModal)
		},
		onSubmit() {
			if (self.modal.data.uuid != undefined) {
				self.onUpdate()
			} else {
				self.onAdd()
			}
		},
		onAdd() {
			let patient = _.pickBy(self.modal.data, (item, key) => {
				switch (typeof item) {
					case 'boolean':
						return true
						break
					case 'string':
						return item.trim() != ''
						break
					default:
						return true
						break
				}
			})

			if (patient.password == undefined || (patient.password && patient.password.length < 6)) {
				self.$notify({type: 'warning', message: self.$i18n.t('error.password_length')})
				return false
			}

			self.modal.loading = true
			self.$store
				.dispatch('PATIENT_ADD', patient)
				.then(resp => {
					self.modalClose()
					self.getData()
					self.$notify({type: 'success', message: self.$i18n.t('success.positive')})
				})
				.catch(err => {
					self.modal.loading = false
					handleError(self, err)
				})
		},
		onUpdate() {
			let uuid = self.modal.data.uuid
			let patient = _.pickBy(self.modal.data, (item, key) => {
				if (key == 'uuid') {
					return false
				}

				switch (typeof item) {
					case 'boolean':
						return true
						break
					case 'string':
						return item.trim() != ''
						break
					default:
						return true
						break
				}
			})

			if (patient.password && patient.password.length < 6) {
				self.$notify({type: 'warning', message: self.$i18n.t('error.passwordstrength')})
				return false
			}

			self.modal.loading = true
			self.$store
				.dispatch('PATIENT_UPDATE', {uuid, patient})
				.then(resp => {
					self.modal.loading = false
					self.getData()
					self.$notify({type: 'success', message: self.$i18n.t('success.positive')})
				})
				.catch(err => {
					self.modal.loading = false
					handleError(self, err)
				})
		},
		getData() {
			self.$store
				.dispatch('PATIENT_REQUEST', {pagination: self.pagination, filter: self.filter})
				.catch(err => {
					handleError(self, err)
				})
				.finally(() => {
					self.loading = false
				})
		},
		getPatientFile(uuid) {
			self.processModal.loading = true
			self.$store
				.dispatch('PATIENT_FILES', {patient: uuid})
				.then(resp => {
					self.processModal.loading = false
				})
				.catch(err => {
					self.processModal.loading = false
					handleError(self, err)
				})
		},
		getPatientPayment(uuid) {
			return new Promise((resolve, reject) => {
				self.processModal.loading = true
				self.$store
					.dispatch('PAYMENT_REQUEST', {
						filter: {patient: uuid, paymentStatus: 'completed, waiting'},
						pagination: {limit: 10000}
					})
					.then(resp => {
						self.processModal.loading = false
						resolve(true)
					})
					.catch(err => {
						self.processModal.loading = false
						handleError(self, err)
						reject(true)
					})
			})
		},
		getPatientAppointment(uuid) {
			return new Promise((resolve, reject) => {
				self.processModal.loading = true
				self.$store
					.dispatch('APPOINTMENT_REQUEST', {
						filter: {patient: uuid, appointmentStatus: 'completed, waiting'},
						pagination: {limit: 10000}
					})
					.then(resp => {
						self.processModal.loading = false
						resolve(true)
					})
					.catch(err => {
						self.processModal.loading = false
						handleError(self, err)
						reject(true)
					})
			})
		},
		getPatientNote(uuid) {
			return new Promise((resolve, reject) => {
				self.processModal.loading = true
				self.$store
					.dispatch('PATIENT_NOTE_REQUEST', {uuid: uuid})
					.then(resp => {
						self.processModal.loading = false
						resolve(true)
					})
					.catch(err => {
						self.processModal.loading = false
						handleError(self, err)
						reject(true)
					})
			})
		},
		getPatientRecipe(uuid) {
			return new Promise((resolve, reject) => {
				self.processModal.loading = true
				self.$store
					.dispatch('RECIPE_REQUEST', {
						filter: {patient: uuid},
						pagination: {limit: 10000}
					})
					.then(resp => {
						self.processModal.loading = false
						resolve(true)
					})
					.catch(err => {
						self.processModal.loading = false
						handleError(self, err)
						reject(true)
					})
			})
		},
		getPatientReport(uuid) {
			return new Promise((resolve, reject) => {
				self.processModal.loading = true
				self.$store
					.dispatch('REPORT_REQUEST', {
						filter: {patient: uuid},
						pagination: {limit: 10000}
					})
					.then(resp => {
						self.processModal.loading = false
						resolve(true)
					})
					.catch(err => {
						self.processModal.loading = false
						handleError(self, err)
						reject(true)
					})
			})
		}
	},
	created() {
		self = this
		self.getData()
	}
}
</script>
